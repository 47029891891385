import { Button, Container, Text, Img, Hr } from '@react-email/components';
import { EmailTemplateLayout } from './components/Layout';
import { btnPrimary } from './components/styles';
import { EmailTemplateBase } from './types';
import { LPLogo } from './components';

function Content(props: EmailTemplateCourseGenerationProps): JSX.Element {
  return (
    <EmailTemplateLayout
      style={{
        maxWidth: '640px',
      }}
    >
      <Container>
        <LPLogo
          style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 4 }}
        />

        <Text>👋 Hi {props.recipientName},</Text>

        <Text>
          Your course "{props.courseName}" has finished generating! Click below
          to preview and edit your course.
        </Text>

        {props.courseCoverImageUrl && (
          <Img
            src={props.courseCoverImageUrl}
            alt='Course Cover'
            style={{
              width: '100%',
              height: 'auto',
              borderRadius: '12px',
              margin: '24px 0',
            }}
          />
        )}

        <Container style={{ textAlign: 'center' }}>
          <Button
            href={props.previewCourseUrl}
            style={{
              ...btnPrimary,
              backgroundColor: '#ff0033',
              color: '#fff',
              padding: '12px 20px',
              borderRadius: '8px',
              textAlign: 'center' as const,
              width: '70%',
              display: 'inline-block',
            }}
          >
            Preview Course
          </Button>

          <Button
            href={props.editCourseUrl}
            style={{
              ...btnPrimary,
              backgroundColor: '#383838',
              color: '#ffffff',
              padding: '12px 20px',
              borderRadius: '8px',
              textAlign: 'center' as const,
              width: '70%',
              display: 'inline-block',
              marginTop: '12px',
            }}
          >
            Edit Course
          </Button>
        </Container>

        <Hr style={{ margin: '40px 0' }} />

        <Text
          style={{
            textAlign: 'center',
            color: '#666',
            fontSize: '14px',
          }}
        >
          We harness the power of games to boost engagement and train teams,
          driving stronger business outcomes.
        </Text>

        <Text
          style={{
            textAlign: 'center',
            color: '#999',
            fontSize: '12px',
          }}
        >
          All rights reserved.
        </Text>

        <Text
          style={{
            textAlign: 'center',
            fontSize: '14px',
          }}
        >
          <a
            href='https://lunapark.com'
            style={{ color: '#000', textDecoration: 'none' }}
          >
            LunaPark.com
          </a>
        </Text>
      </Container>
    </EmailTemplateLayout>
  );
}

export interface EmailTemplateCourseGenerationProps {
  recipientName: string;
  courseName: string;
  courseCoverImageUrl?: string;
  previewCourseUrl: string;
  editCourseUrl: string;
}

export const EmailTemplateCourseGeneration: EmailTemplateBase<EmailTemplateCourseGenerationProps> =
  {
    Key: 'course-generation-complete',
    Name: 'Course Generation Complete',
    Description: 'Notification when course generation is complete',
    Subject: (props) => `Your course "${props.courseName}" is ready to preview`,
    Content,
    PreviewProps: {
      recipientName: 'there',
      courseName: 'My Course',
      courseCoverImageUrl:
        'https://assets.golunapark.com/static/images/email/example-course-cover.png',
      previewCourseUrl: 'https://lunapark.com/training/123/editor?preview=true',
      editCourseUrl: 'https://lunapark.com/training/123/editor',
    },
  };
